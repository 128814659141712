import { Environment } from './environment.model';

export const environment: Environment = {
  production: true,
  skillsPortalUrl: 'https://sxsap.beebase.dev',
  assessmentUrl: 'https://go.proctoru.com/students/users/new',
  candidateCambrigdeResultUrl: 'https://candidates.cambridgeenglish.org',
  schoolCambrigdeResultUrl: 'https://preparationcentres.cambridgeenglish.org',
  goetheResultUrl: 'https://goethe-pruefungen.swiss-exams.ch/de/ergebnis-und-zertifikat',
  tcfResultUrl: 'https://swiss-exams.ch/results-tcf',
  customerServicePortalUrl: 'https://support.swiss-exams.ch',
  loadFeatureFlags: true,
  languages: ['de', 'en', 'fr'],
  revokeAvailabilityRestrictionInMonths: 2,
  availabilityIndicatorMonths: 3,
  examDateFinalizationInDays: 3,
  linguaSkillVideoUrl: 'https://www.youtube-nocookie.com/embed/HXDax1OjmLQ',
  sentryDsn: 'https://7d1579acd5b14e05823bcc3e95c18365@o1087817.ingest.sentry.io/4504093977280512',
  // This value will be set via gitlab
  sentryVersion: '76f1da2e2940923d19f773865b12a522584f849a',
  baseUrl: 'https://access-test.swiss-exams.ch',
  env: 'Testing',
  sentryTracesSampleRate: 0.75,
  enableSentry: true,
};
